<template>
  <div v-if="cruci !== undefined">
    <!-- <pre>{{ cruci }}</pre> -->
    <Grilla :key="count" :crucigrama="cruci" @repaint="repaint" />
  </div>
</template>
<script>
import Grilla from '../components/Grilla.vue';
import { mapState } from 'vuex';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
export default {
  name: 'Play',
  components: {
    Grilla,
  },
  data() {
    return {
      cruci: undefined,
      users: {},
      count: 0,
    };
  },
  computed: {
    ...mapState(['credentials']),
  },
  async created() {
    this.setLoading(true);
    let cruciSocial = this.cruciData()[this.$route.params.cruciId];
    //debugger;
    cruciSocial = cruciSocial ? cruciSocial : { ...JSON.parse(localStorage.getItem(this.$route.params.cruciId + 'cruciData')), fromLocal: true };
    cruciSocial = cruciSocial ? cruciSocial : {};

    const docRef = doc(getFirestore(), 'crosswords', this.$route.params.cruciId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    for (let field in data) {
      cruciSocial[field] = data[field];
    }
    cruciSocial.id = this.$route.params.cruciId;
    this.cruci = cruciSocial;
    this.count++;
  },
  methods: {
    repaint() {
      let cruciSocial = this.cruciData()[this.$route.params.cruciId];
      cruciSocial = cruciSocial ? cruciSocial : { ...JSON.parse(localStorage.getItem(this.$route.params.cruciId + 'cruciData')), fromLocal: true };
      cruciSocial = cruciSocial ? cruciSocial : {};
      cruciSocial.id = this.$route.params.cruciId;
      this.cruci = cruciSocial;
      this.count++;
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.cruci {
  padding-top: 8px;
  vertical-align: top;
}
</style>
