<template>
  <div class="grilla-top" :style="`overflow: hidden;opacity:${uid === undefined || cruci?.public === true ? '.85;' : '.6;border:2px solid red;'}`">
    <router-link style="cursor: pointer; position: absolute; margin-top: 5px" tabindex="-1" :to="'/' + users[cruci.uid]?.username"><img :src="users[cruci.uid]?.avatar" alt="" /></router-link>
    <strong style="position: absolute; white-space: nowrap; letter-spacing: 0px; margin-left: 52px; margin-top: -4px; font-size: 12px"> {{ users[cruci.uid]?.username }}, {{ formatDate(cruci?.fecha?.seconds) }}</strong>
    <strong style="position: absolute; letter-spacing: 1px; margin-left: 52px; margin-top: 13px; color: yellow; font-size: 14px"> <ThemifyIcon style="font-size: 12px" icon="eye"></ThemifyIcon>{{ cruciDat ? cruciDat.plays : cruci?.plays }} <ThemifyIcon style="font-size: 12px" icon="star"></ThemifyIcon>{{ cruciDat ? cruciDat.votos : cruci ? cruci.votos : 0 }}x{{ Math.round((cruciDat ? cruciDat.stars : cruci ? cruci.stars : 0) / Math.max(1, cruciDat ? cruciDat.votos : cruci ? cruci.votos : 0)) }} <ThemifyIcon icon="timer" style="font-size: 12px"></ThemifyIcon>{{ cruci?.timesCount }}×{{ ftime }}</strong>
    <button v-if="action" tabindex="-1" class="action-button" :style="`${percent === 100 ? 'box-shadow: rgba(255,255,255, 0.75) 0px 0px 15px;' : ''}`" @click="action.click" style="background: #433">
      <canvas ref="canvas" style="position: relative; left: -8px"></canvas>
      <ThemifyIcon style="font-size: 24px; position: relative; top: -38px; left: 0" :icon="action.icon" />
      <ThemifyIcon v-if="percent === 100" style="font-size: 18px; position: absolute; top: 3px; right: -2px" icon="medall" />

      <ThemifyIcon v-if="cruci?.uid === uid" style="font-size: 12px; position: absolute; top: 38px; right: 2px" :icon="cruci?.public === true ? 'world' : 'na'" />
    </button>

    <TagEditor :colors="['var(--color-select)', 'var(--color-gradient)']" style="text-shadow: none; position: relative; top: 33px; width: calc(100% - 100px); height: 34px; left: 48px; overflow-x: auto; white-space: nowrap" :tagLoad="cruci.desc" :editable="false" :clickMethod="clickMethod" />
  </div>
</template>
<script>
import TagEditor from '../components/TagEditor.vue';
import ThemifyIcon from 'vue-themify-icons';
import { mapGetters } from 'vuex';
let count = 0;
export default {
  name: 'GrillaCard',
  props: {
    percent: Number,
    action: {
      type: Object,
      default: null,
    },
    cruci: {
      type: Object,
      required: true,
    },
  },
  watch: {
    cruci: {
      async handler() {
        this.users = await this.getUsers([this.cruci.uid]);
      },
      deep: true,
    },
  },
  mounted() {
    for (let i = 0; i < 20; i++) {
      this.burbujas.push({
        x: Math.random() * 55,
        y: Math.random() * 58,
        r: 1 + Math.random(),
        v: 0.5 + Math.random(),
      });
    }
    this.cruciDat = this.cruciData()[this.$route.params.cruciId];
    //console.log(this.$route.params.cruciId, this.cruciDat);
    this.interval = setInterval(() => {
      this.draw(this.percent);
    }, 20);
  },
  data() {
    return {
      users: {},
      count: 0,
      burbujas: [],
      cruciDat: undefined,
      interval: undefined,
    };
  },
  methods: {
    draw(val) {
      this.count += 0.2;
      const canvas = this.$refs.canvas;
      if (canvas) {
        canvas.width = 55;
        canvas.height = 54;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, 55, 54);
        ctx.fillStyle = 'rgba(' + Math.trunc((255 * (100 - this.percent)) / 100) + ',73,' + Math.trunc((255 * this.percent) / 100) + ',0.5)';
        for (let j = 1; j <= 3; j++) {
          ctx.beginPath();
          ctx.moveTo(55, 54);
          ctx.moveTo(0, 54);
          for (let i = 0; i < 55; i++) {
            ctx.lineTo(i, 57 + Math.trunc(3 * Math.sin(((i + this.count * ((j - 1.5) * 1.2)) / 45) * Math.PI * 2)) - (58 * this.percent) / 100);
          }
          ctx.lineTo(55, 54);
          ctx.fill();
        }
        ctx.fillStyle = 'rgba(255,255,255,0.2)';
        for (let i = 0; i < this.burbujas.length; i++) {
          const burbuja = this.burbujas[i];
          ctx.beginPath();
          ctx.arc(burbuja.x, burbuja.y + 58 - (58 * this.percent) / 100, burbuja.r, 0, 2 * Math.PI);
          ctx.fill();
          burbuja.y -= burbuja.v / 2;
          burbuja.x += Math.random() - 0.5;
          if (burbuja.y < 0) {
            burbuja.x = Math.random() * 55;
            burbuja.y = 58;
            burbuja.r = 1 + Math.random();
            burbuja.v = 0.5 + Math.random();
          }
        }
      }
    },
    profile() {
      this.$router.push('/' + this.users[this.cruci.uid]?.username);
    },
    clickMethod(value) {
      if (this.$route.fullPath !== '/' + value) {
        this.$router.push('/' + value);
      }
    },
  },

  destroyed() {
    clearInterval(this.interval);
  },

  computed: {
    ...mapGetters(['uid']),
    ftime() {
      const time = Math.round((this.cruci.times ? this.cruci.times : 0) / Math.max(1, this.cruci.timesCount ? this.cruci.timesCount : 0));
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },
  components: {
    TagEditor,
    ThemifyIcon,
  },
  async created() {
    this.users = await this.getUsers([this.cruci.uid]);
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.cruci {
  padding-top: 8px;
  vertical-align: top;
}
.action-button {
  overflow: hidden;
  position: absolute;
  right: -1px;
  height: 43px;
  width: 34px;
  border-radius: 10px;

  background-color: #434;
  box-shadow: rgba(148, 148, 148, 0.2) 0px 0px 0px 2px;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);

  color: var(--color-negate-select);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  color: yellow;
  top: -1px;
  width: 48px;
  height: 56px;
}

.grilla-top img {
  width: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 1px;
  background: lightgray;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}
.grilla-top a {
  color: yellow;
}

.grilla-top {
  border: 1px solid black;
  color: white;
  background: rgb(18, 39, 59) linear-gradient(120deg, rgba(114, 11, 11, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
  padding: 8px 4px 4px;
  height: 71px;
  border-radius: 8px 14px 14px 8px;
  margin: 6px 4px 4px;
}

.grilla-top div {
  margin-bottom: 8px;
  font-size: 12px !important;
}
.grilla-top button {
  float: right;
  margin-left: 8px !important;
  margin: 0;
  font-size: 12px;
  padding: 0 6px;
  position: relative;
  top: -2px;
}
</style>
