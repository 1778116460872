<template>
  <div class="grid">
    <div class="ayuda" @click="click('tip', $event)" :style="{ zoom: isMobile ? undefined : 1.33 }" v-html="palabra ? palabra : (isMobile ? '' : `<div class='disorder'>` + disorder + `</div>`) + `${llenado}`"></div>
    <input
      type="text"
      v-if="!isMobile"
      ref="input"
      @keydown="
        key($event);
        $event.target.value = '';
      "
      @blur="refocus"
      style="overflow: hidden; opacity: 0; height: 0"
    />
    <div v-if="preg" class="preg" :style="isMobile ? { gridTemplateColumns: '42px 1fr 42px' } : {}">
      <div style="overflow: visible">
        <button @pointerdown="click('<', $event)" class="topButton" :style="isMobile ? '' : 'padding-top:-20px;height:80px;top:-10px;line-height:-30px'">
          <ThemifyIcon style="font-size: 22px; font-weight: bolder" icon="angle-double-left"></ThemifyIcon><br v-if="!isMobile" />
          <span v-if="!isMobile" style="font-size: 9px">CTRL+</span><span v-if="!isMobile" style="font-size: 22px; line-height: 16px">←</span><br v-if="!isMobile" />
          <span v-if="!isMobile" style="font-size: 9px">MAYUS+TAB</span>
        </button>
      </div>
      <div class="text" @click="click('tip', $event)" ref="pregu" :style="{ fontSize: isMobile ? undefined : '17px' }">
        {{ preg.toUpperCase() }}
      </div>
      <div style="overflow: visible">
        <button @pointerdown="click('>', $event)" class="topButton" :style="isMobile ? '' : 'padding-top:-20px;height:80px;top:-10px;line-height:-30px'">
          <ThemifyIcon style="font-size: 22px; font-weight: bolder" icon="angle-double-right"></ThemifyIcon><br v-if="!isMobile" />
          <span v-if="!isMobile" style="font-size: 9px">CTRL+</span><span v-if="!isMobile" style="font-size: 22px; line-height: 16px">→</span><br v-if="!isMobile" />
          <span v-if="!isMobile" style="font-size: 9px">TAB</span>
        </button>
      </div>
    </div>
    <div @click="click('', $event)" :style="'position: relative; top: 6px; height: 10px; line-height: ' + (isMobile ? 0 : 10) + 'px; width: 100%; grid-column: span 10'">
      {{ scroll ? '⇓⇓⇓' : '' }}
    </div>
    <template v-if="isMobile">
      <template v-for="keyRow in keys">
        <button v-for="key in keyRow" class="key" @pointerdown="click(key, $event)" :key="key ? key : Math.random()" :style="{ borderRadius: '3px', height: (sizeK === 0 ? 30 : sizeK === 1 ? 36 : 45) + 'px', background: resp.indexOf(key) !== -1 ? 'rgba(162,172,92)' : 'auto', opacity: key === '' || '1234'.includes(key) ? 0 : 1, 'grid-column': 'span ' + (span[key] ? span[key] : 1) }">{{ key }}</button>
      </template>
    </template>
  </div>
</template>
<script>
import ThemifyIcon from 'vue-themify-icons';
export default {
  props: ['keys', 'llenado', 'span', 'preg', 'palabra', 'resp', 'sizeK'],
  components: {
    ThemifyIcon,
  },
  data() {
    return {
      isMobile: (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) !== null,
      scroll: false,
    };
  },

  watch: {
    resp: {
      handler(val) {
        //console.log('resp', val);
      },
      immediate: true,
      deep: true,
    },
    preg: {
      handler(val) {
        this.$nextTick(() => {
          setTimeout(() => {
            const pregElement = this.$refs.pregu;
            if (pregElement) {
              this.scroll = pregElement.scrollHeight - 10 > pregElement.clientHeight;
            }
          }, 100);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    disorder() {
      const val = this.resp.substring(0, this.resp.length).replace(/_/g, '');
      let disorder = val;
      if (disorder.length > 0) {
        disorder = disorder.split('').sort().join('');
      }
      //console.log(disorder);
      return disorder;
    },
  },
  mounted() {
    if (!this.isMobile) {
      try {
        this.$refs.input.focus();
      } catch (error) {}
    }
  },
  methods: {
    refocus() {
      this.$refs.input.focus();
    },
    key(evt) {
      this.$emit('keyboard', evt);
      evt.preventDefault();
      evt.stopPropagation();
    },
    click(key, evt) {
      if (!evt?.target.disabled) {
        // if (evt?.target) {
        //   if (evt?.target?.className === 'key' && this.llenado !== '') {
        //     //console.log(evt?.target?.className);
        //     evt.target.style.background = 'red';
        //     setTimeout(() => {
        //       evt.target.style.background = this.resp.indexOf(key) !== -1 ? 'rgba(162,172,92)' : 'linear-gradient(10deg, var(--color-texto), var(--color-gradient) 40px)';
        //       evt.target.style.top = '3px';
        //     }, 150);
        //   }
        // }
        this.$emit('keyPress', key, [-0.5 + evt.offsetX / evt.target.offsetWidth, -0.5 + evt.offsetY / evt.target.offsetHeight]);
      }
    },
  },
};
</script>
<style scoped>
.ayuda {
  font-family: 'Ubuntu Mono', monospace;
  font-variant-numeric: tabular-nums;
  line-height: 20px;
  position: relative;
  top: -2px;
  padding: 1px 0 0;
  font-size: 20px;
  letter-spacing: 4px;
  grid-column: span 10;
  color: white;
  text-align: center;
  cursor: help;
}
.preg {
  padding: 0;
  height: 78px;
  grid-column: span 10;
  /* padding: 8px; */
  color: yellow;
  font-weight: bolder;
  display: grid;
  column-gap: 2px;
  text-align: left !important;
  grid-template-columns: 72px 1fr 72px;
}

.preg .text {
  font-size: 18px;
  padding: 4px;
  color: cyan;
  text-shadow: 0 0 2px black;
  display: grid;
  place-items: center;
  cursor: help;
}

.preg div {
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
}
.preg button {
  margin: 0;
  opacity: 0.9;
}
button.topButton {
  font-size: 18px !important;
  height: 56px;
  margin-top: 0px;
  padding-top: 10px;
}

.grid {
  touch-action: none;
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 0;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #121;
  padding: 0 0 0 4px;
  box-shadow: rgba(50, 50, 93, 0.45) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  opacity: 0.97;
  padding: 4px 4px 10px;
}

input {
  width: 100%;
  grid-column: span 10;
  text-align: center;
}
</style>
