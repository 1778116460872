<template>
  <div
    style="display: grid; place-items: center; touch-action: none"
    @click="
      _visible = false;
      $router.replace($route.fullPath.substring(0, $route.fullPath.lastIndexOf('#'))).catch(() => {});
    "
  >
    <div v-if="isVisible" class="modal" :style="{ background: color, overflowY: 'scroll', display: 'grid', 'place-items': 'center' }">
      <button style="width: 32px; padding: 6px 1px 3px !important; background: yellow; border: 2px solid black; border-radius: 6px; position: fixed; top: 20px; right: 20px; z-index: 10000; opacity: 0.6">X</button>
      <div class="grid">
        <template v-for="(item, idx) in data" :style="item.style">
          <h1 :key="idx" v-if="item.h1" v-html="item.h1" :style="item.style"></h1>
          <h2 :key="idx" v-if="item.h2" v-html="item.h2" :style="item.style"></h2>
          <h3 :key="idx" v-if="item.h3" v-html="item.h3" :style="item.style"></h3>
          <h4 :key="idx" v-if="item.h4" v-html="item.h4" :style="item.style"></h4>
          <h5 :key="idx" v-if="item.h5" v-html="item.h5" :style="item.style"></h5>
          <h6 :key="idx" v-if="item.h6" v-html="item.h6" :style="item.style"></h6>
          <hr :key="idx" v-if="item.hr" v-html="item.hr" :style="item.style" />
          <p :key="idx" v-if="item.p" v-html="item.p" :style="item.style"></p>
          <iframe :key="idx" v-if="item.iframe" :src="item.id"></iframe>
          <input :key="idx" v-if="item.input" :value="item.value" :style="item.style" @keyup="resp[item.id] = $event.target.value" />
          <br :key="idx" v-if="item.br" />
          <button
            :key="idx"
            v-html="item.button"
            :style="item.style"
            v-if="item.button"
            @click="
              if (item.click === '') {
                _visible = false;
                lastdialogId = '#' + name;
              }
              if (item.router) {
                $emit('submit', { router: item.router, data: resp });
              } else {
                $emit('submit', { click: item.id, data: resp });
              }
              if (item.click === '') {
                $router.replace($route.path).catch(() => {});
              }
              $event.stopPropagation();
            "
          ></button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      resp: {},
      lastdialogId: '',
      isVisible: false,
    };
  },
  methods: {
    setTimeout: function (fn, time) {
      return setTimeout(fn, time);
    },
  },
  watch: {
    visible: function (newVal) {
      this.isVisible = newVal;
      if (newVal) {
        this.$router.replace('#' + this.name).catch(() => {});
      }
    },
    '$route.hash': {
      handler: function (dialog_id) {
        if ('#' + this.name === dialog_id && dialog_id === this.lastdialogId && this.lastdialogId === '#' + this.name && !this.isVisible) {
          const delay = this.delayTime();
          console.log('name', this.name, 'dialog_id:', dialog_id, 'lastdialogId:', this.lastdialogId, 'isVisible:', this.isVisible, 'delay:', delay);
          if (delay > 1000) {
            this._visible = false;
            this.$router.push('/').catch(() => {});
          }
        } else if (this.isVisible) {
          if (dialog_id === '') {
            this.$emit('submit', { click: '' });
          } else {
            this.lastdialogId = dialog_id;
          }
        } else {
          //Eliminato el historial de navegacion
          if (dialog_id === '') {
            if (this.lastdialogId === '#' + this.name) {
              this._visible = false;
            }
          }
        }
      },
      immediate: true,
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    visible: {
      default: false,
    },
    audio: {
      default: 1,
    },
    color: {
      default: 'blue',
    },
    data: {
      default: [],
    },
  },
};
</script>
<style scoped>
.grid {
  max-width: 800px;
  width: 100%;
  margin: 32px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
}

.grid * {
  grid-column: span 6;
}

.modal p {
  text-align: left;
  font-size: 20px;
  max-width: 1200px !important;
  margin: 16px auto;
}
.modal hr {
  width: 100%;
  height: 8px;
  border-top: 1px solid white;
}

.modal {
  text-align: left;
  z-index: 1000;
}
.modal button {
  width: 100%;
  line-height: 1.66;
  display: inline-block;
  margin-bottom: 16px;
  font-weight: bolder;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
