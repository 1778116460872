var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"grid","place-items":"center","touch-action":"none"},on:{"click":function($event){_vm._visible = false;
    _vm.$router.replace(_vm.$route.fullPath.substring(0, _vm.$route.fullPath.lastIndexOf('#'))).catch(() => {});}}},[(_vm.isVisible)?_c('div',{staticClass:"modal",style:({ background: _vm.color, overflowY: 'scroll', display: 'grid', 'place-items': 'center' })},[_c('button',{staticStyle:{"width":"32px","padding":"6px 1px 3px !important","background":"yellow","border":"2px solid black","border-radius":"6px","position":"fixed","top":"20px","right":"20px","z-index":"10000","opacity":"0.6"}},[_vm._v("X")]),_c('div',{staticClass:"grid"},[_vm._l((_vm.data),function(item,idx){return [(item.h1)?_c('h1',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h1)}}):_vm._e(),(item.h2)?_c('h2',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h2)}}):_vm._e(),(item.h3)?_c('h3',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h3)}}):_vm._e(),(item.h4)?_c('h4',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h4)}}):_vm._e(),(item.h5)?_c('h5',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h5)}}):_vm._e(),(item.h6)?_c('h6',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.h6)}}):_vm._e(),(item.hr)?_c('hr',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.hr)}}):_vm._e(),(item.p)?_c('p',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.p)}}):_vm._e(),(item.iframe)?_c('iframe',{key:idx,attrs:{"src":item.id}}):_vm._e(),(item.input)?_c('input',{key:idx,style:(item.style),domProps:{"value":item.value},on:{"keyup":function($event){_vm.resp[item.id] = $event.target.value}}}):_vm._e(),(item.br)?_c('br',{key:idx}):_vm._e(),(item.button)?_c('button',{key:idx,style:(item.style),domProps:{"innerHTML":_vm._s(item.button)},on:{"click":function($event){if (item.click === '') {
              _vm._visible = false;
              _vm.lastdialogId = '#' + _vm.name;
            }
            if (item.router) {
              _vm.$emit('submit', { router: item.router, data: _vm.resp });
            } else {
              _vm.$emit('submit', { click: item.id, data: _vm.resp });
            }
            if (item.click === '') {
              _vm.$router.replace(_vm.$route.path).catch(() => {});
            }
            $event.stopPropagation();}}}):_vm._e()]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }