<template>
  <div style="padding-left: 24px">
    <div style="position: fixed; width: calc(100% - 55px); top: 17px; padding: 16px 4px 0; z-index: 1; margin: 0 auto; overflow: hidden !important">
      <GrillaCard @pointerup="grillaClick" style="margin: 10px auto 0; max-width: 1200px; position: relative" :plays="cruciData()[cruci.id]?.plays" :stars="cruciData()[cruci.id] ? cruciData()[cruci.id].stars / Math.max(cruciData()[cruci.id].votos, 1) : undefined" :times="cruciData()[cruci.id] ? Math.round(cruciData()[cruci.id].times / Math.max(1, cruciData()[cruci.id].timesCount)) : undefined" :percent="percent" :cruci="cruci" :action="{ icon: 'menu', click: keyCfg }" />
    </div>

    <div class="grill" ref="grill" tabindex="-1">
      <span v-if="!showCfg" ref="ftime" style="position: fixed; left: 3px; top: 6px; padding: 0 3px; color: black; z-index: 900; border-radius: 7px; background: var(--color-select); box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; font-size: 14px; font-weight: bolder; font-family: monospace"></span>
      <div class="grilla-container" ref="grillaCont" @keyup="key($event)" :style="{ overflowX: isMobile ? 'auto' : 'hidden' }">
        <br />
        <div class="grilla-canvas-container">
          <canvas class="grilla-canvas" :style="{ background: grillaColor, top, left }" ref="grillaBg"> </canvas>
          <pre class="grilla-cursor" :style="cursorStyle" ref="cursorDiv"></pre>
        </div>
      </div>
      <pre @pointerup="grillaClick" @mouseleave="mouseleave" @pointerdown="grillaDown" @wheel.prevent="wheel($event)" @pointermove="grillaMove" class="grilla" :style="`padding-left:${this.grillaAddX[this.size]}px;color:${this.grillaColor}; letter-spacing: ${this.grillaGapHorizontal[this.size]}px; font-size: ${this.grillaFontSize[this.size]}px; line-height: ${this.grillaLineHeight[this.size]}px;padding-right:24px;text-shadow: 0 0 1px black;color:black`" ref="grilla">{{ dataText }}</pre>

      <MobileKeyboard :llenado="llenado" :sizeK="sizeK" ref="keyComponent" :pos="click[0] - pregunta?.y + click[1] - pregunta?.x" :largo="pregunta?.palabra.length" @keyboard="keyboard" :keys="keys" :span="{}" :preg="pregunta?.pregunta" :resp="tipWord()" @keyPress="keyPress" :palabra="pregunta?.ok ? pregunta?.palabra : undefined" :key="tipWord()" style="margin-top: 40px" />

      <Modal
        name="CFG"
        :audio="audio"
        :visible="showCfg"
        @submit="submit2"
        :color="`url(/img/bg${fondo}.png)`"
        :data="//
        [
          this.cruci.uid === this.uid ? { button: '<i style=\'font-size:24px;padding-left:8px\' class=\'ti-' + (cruci.public ? 'na' : 'world') + '\'></i>&nbsp;&nbsp;' + (cruci.public ? 'OCULTAR' : 'PUBLICAR') + ' CRUCIGRAMA', id: 'publicar', style: 'background:yellow;grid-column: span 3;' } : {},
          this.cruci.uid === this.uid ? { button: '<i style=\'font-size:24px;padding-left:8px\' class=\'ti-trash\'></i>&nbsp;&nbsp;ELIMINAR CRUCIGRAMA', id: 'delete', style: 'background:yellow;grid-column: span 3;' } : {},
          //
          { button: '<i style=\'font-size:24px\' class=\'ti-reload\'></i><br>REINICIAR EL<BR>CRUCIGRAMA', id: 'reinit', style: 'grid-column: span 3;color:yellow;font-weight:bolder;font-size:16px;text-shadow: 0px 0px 2px black, 0px 0px 4px black;display:block' },
          { button: '<i style=\'font-size:24px\' class=\'ti-sharethis\'></i><br>COMPARTIR EL<BR>CRUCIGRAMA', id: 'share', style: 'grid-column: span 3;color:yellow;font-weight:bolder;font-size:16px;text-shadow: 0px 0px 2px black, 0px 0px 4px black;margin-bottom:20px;display:block;opacity:.5' },

          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-star\'></i>', id: 'star1', style: 'grid-column: span 1;background:black;border:none;' + (stars > 0 ? 'color:yellow' : 'color:gray') } : {},
          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-star\'></i>', id: 'star2', style: 'grid-column: span 1;background:black;border:none;' + (stars > 1 ? 'color:yellow' : 'color:gray') } : {},
          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-star\'></i>', id: 'star3', style: 'grid-column: span 1;background:black;border:none;' + (stars > 2 ? 'color:yellow' : 'color:gray') } : {},
          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-star\'></i>', id: 'star4', style: 'grid-column: span 1;background:black;border:none;' + (stars > 3 ? 'color:yellow' : 'color:gray') } : {},
          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-star\'></i>', id: 'star5', style: 'grid-column: span 1;background:black;border:none;' + (stars > 4 ? 'color:yellow' : 'color:gray') } : {},
          this.uid ? { button: '<i style=\'font-size:32px\' class=\'ti-comment-alt\'></i>', id: 'comment', style: 'grid-column: span 1;background:black;border:none;color:yellow;opacity:.5' } : {},

          this.comments ? { iframe: '', id: 'comment', style: 'position:relative;top:-3px;grid-column: span 6;background:black;border:none;opacity:.4' + (true ? ';color:yellow' : '') } : {},

          { br: '&nbsp;' },

          { h2: 'Tamaño del Crucigrama' },
          { button: 'CHICO', id: 'size0', style: 'grid-column: span 2;' + (this.size == 0 ? ';background:yellow;color:black' : '') },
          { button: 'MEDIO', id: 'size1', style: 'grid-column: span 2;' + (this.size == 1 ? ';background:yellow;color:black' : '') },
          { button: 'GRANDE', id: 'size2', style: 'grid-column: span 2;' + (this.size == 2 ? ';background:yellow;color:black' : '') },
          //
          !this.isMobile ? {} : { h2: 'Temaño del Teclado' },
          !this.isMobile ? {} : { button: 'CHICO', id: 'sizeK0', style: 'grid-column: span 2;' + (this.sizeK == 0 ? ';background:yellow;color:black' : '') },
          !this.isMobile ? {} : { button: 'MEDIO', id: 'sizeK1', style: 'grid-column: span 2;' + (this.sizeK == 1 ? ';background:yellow;color:black' : '') },
          !this.isMobile ? {} : { button: 'GRANDE', id: 'sizeK2', style: 'grid-column: span 2;' + (this.sizeK == 2 ? ';background:yellow;color:black' : '') },
          //
          { h2: 'Fondo ' },
          { button: 'FONDO1', id: 'fondo1', style: 'grid-column: span 2;' + (this.fondo == 1 ? ';background:yellow;color:black' : '') },
          { button: 'FONDO2', id: 'fondo2', style: 'grid-column: span 2;' + (this.fondo == 2 ? ';background:yellow;color:black' : '') },
          { button: 'FONDO3', id: 'fondo3', style: 'grid-column: span 2;' + (this.fondo == 3 ? ';background:yellow;color:black' : '') },
          //
          { h2: 'Set de sonidos' },
          { button: 'SET1', id: 'set1', style: 'grid-column: span 2;' + (this.audio == 1 ? ';background:yellow;color:black' : '') },
          { button: 'SET2', id: 'set2', style: 'grid-column: span 2;' + (this.audio == 2 ? ';background:yellow;color:black' : '') },
          { button: 'MUDO', id: 'mudo', style: 'grid-column: span 2;' + (this.audio == 3 ? ';background:yellow;color:black' : '') },

          //
          // { h2: 'Paleta de colores' },
          // { button: 'PALETA 1', id: 'paleta1', style: 'grid-column: span 2;' },
          // { button: 'PALETA 2', id: 'paleta2', style: 'grid-column: span 2;' },
          // { button: 'PALETA 3', id: 'paleta3', style: 'grid-column: span 2;' },

          //
          { h2: 'Opciones Avanzadas' },

          !this.isMobile ? {} : { button: 'CORREGIR<br>TECLAS<br>CERCANAS', id: 'teclasCercanas', style: 'grid-column: span 2;font-size:10px;' + (this.teclasCercanas ? ';background:yellow;color:black' : '') },
          { button: 'MOSTRAR<br>COMPLETITUD<br>DE PALABRAS', id: 'mostrarCompletitud', style: 'grid-column: span 2;font-size:10px;' + (this.mostrarCompletitud ? ';background:yellow;color:black' : '') },

          { button: 'LECTOR DE<br>PREGUNTA', id: 'lectorPregunta', style: 'grid-column: span 2;font-size:10px;' + (this.lectorPregunta ? ';background:yellow;color:black' : '') },
          this.uid !== undefined && this.cruci.uid === this.uid
            ? {
                p:
                  '<div style=\'background: black;border-radius:12px; padding:16px;grid-column: span 6 / auto;width:100%;font-size:12px;\' onclick=\'event.preventDefault();event.stopPropagation()\'><h2>EDITAR PREGUNTAS</h2>' +
                  this.cruci.preguntas
                    .map((p, i) => {
                      return '<button onclick=\'editCruci(event,0,' + i + ')\' style=\'background:red\' class=\'bttn\'>X</button>' + (p.horizontal ? 'H' : 'V') + ':' + p.x + ':' + p.y + '<button onclick=\'editCruci(event,1,' + i + ')\' class=\'bttn\'>✎</button> ' + p.palabra + '<button  onclick=\'editCruci(event,2,' + i + ')\' class=\'bttn\'>✎</button> ' + p.pregunta + '<button  onclick=\'editCruci(event,3,' + i + ')\' class=\'bttn\'>✎</button><br>';
                    })
                    .join(' ') +
                  '<br><button onclick=\'editCruci(event,5)\'> Agregar Pregunta </button><button style=\'float:right;background:red;\' onclick=\'editCruci(event,4)\'> Guardar </button></div>',
              }
            : {},
        ]"
      ></Modal>
    </div>

    <div :style="{ height: isMobile ? '290px' : '150px' }">&nbsp;</div>
    <div v-if="confetti > 0" :style="{ opacity: confetti }" class="confetti">&nbsp;</div>
  </div>
</template>
<script>
import Speech from 'speak-tts';
import MobileKeyboard from './MobileKeyboard.vue';
import { mapGetters, mapState } from 'vuex';
import Modal from '../components/Modal.vue';
import GrillaCard from '../components/GrillaCard.vue';
import { getFirestore, doc, updateDoc, increment, deleteDoc, Timestamp } from 'firebase/firestore';
const SPACE_CHAR = '·';
const BLACK_CHAR = ' ';
let lastStore = 0;
const speech = new Speech();
let cruci = null;
let data22 = null;

window.editCruci = async function (evt, tipo, pregIdx) {
  let preg = '';
  if (tipo === 0) {
    preg = confirm('Borrar pregunta?');
    if (preg === true) {
      cruci.preguntas.splice(pregIdx, 1);
    }
  }
  if (tipo === 1) {
    preg = prompt('Posición', (cruci.preguntas[pregIdx].horizontal ? 'H' : 'V') + ':' + cruci.preguntas[pregIdx].x + ':' + cruci.preguntas[pregIdx].y);
    if (preg !== null) {
      const pos = preg.trim().toUpperCase().split(':');
      cruci.preguntas[pregIdx].horizontal = pos[0] === 'H';
      cruci.preguntas[pregIdx].x = parseInt(pos[1]);
      cruci.preguntas[pregIdx].y = parseInt(pos[2]);
    }
  }
  if (tipo === 2) {
    preg = prompt('Palabra', cruci.preguntas[pregIdx].palabra);
    if (preg !== null) {
      cruci.preguntas[pregIdx].palabra = preg.trim().toUpperCase();
    }
  }
  if (tipo === 3) {
    preg = prompt('Pregunta', cruci.preguntas[pregIdx].pregunta);
    if (preg !== null) {
      cruci.preguntas[pregIdx].pregunta = preg.trim().toUpperCase();
    }
  }
  if (tipo === 4) {
    const db = getFirestore();
    await updateDoc(doc(getFirestore(), 'crosswords', cruci.id), {
      preguntas: cruci.preguntas,
    });
    localStorage.removeItem(cruci.id + 'cruciData');
    window.location.reload();
  }
  if (tipo === 5) {
    cruci.preguntas.push({
      horizontal: true,
      x: 0,
      y: 0,
      palabra: 'completar',
      pregunta: 'completar',
    });
  }
  evt.stopPropagation();
  evt.preventDefault();
};

export default {
  name: 'Grilla',
  components: {
    Modal,
    MobileKeyboard,
    GrillaCard,
  },
  props: ['crucigrama'],

  data() {
    return {
      interval0: null,
      interval1: null,
      interval2: null,
      interval3: null,
      interval4: null,
      voice: 'Google español',
      speechSupport: speech.hasBrowserSupport(),
      llenado: '',
      top: 0,
      left: 0,
      move: undefined,
      comments: '',
      percent: 0,
      stars: 0,
      showCfg: false,
      time: 0,
      fondo: 2,
      confetti: 0,
      complete: false,
      completado: true,
      isMobile: (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) !== null,
      cruci: {},
      data: [],
      dataText: '',
      grillaColor: 'rgba(0,0,0,.69)',
      grillaColorBlanco: 'white',
      size: 1,
      audio: 1,
      sizeK: 0,
      teclasCercanas: true,
      mostrarCompletitud: true,
      lectorPregunta: false,
      grillaFontSize: [23, 38, 48],
      grillaLineHeight: [24, 38, 48],
      grillaGapHorizontal: [12, 14, 16],
      grillaAddX: [5, 7, 7],
      pregunta: undefined,
      click: [0, 0],
      cursorStyle: 'height: 27px; width: 4px; left: 4px; top: 10px',
      keys: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
        ['1', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '2', '3'],
      ],
    };
  },
  computed: {
    ...mapGetters(['uid']),
    ...mapState(['zoom']),
    getFondo() {
      return this.getLocal('fondo') || 2;
    },
  },
  watch: {
    pregunta: {
      handler: function (val, oldVal) {
        if (this.lectorPregunta && !val.ok) {
          speech.cancel();
          speech.setVolume(1);
          speech.setRate(1.3);
          speech.setPitch(0.75);
          const text = val.pregunta.replace(/[_]+/gi, '_').replace(/[-]+/gi, '-').toLowerCase();
          speech.speak({
            text,
          });
        }
      },
      deep: true,
    },
    time: {
      handler: function (val, oldVal) {
        if (this.$refs.ftime) {
          this.ftime(this.time);
        }
      },
      deep: true,
    },
    showCfg: function (val) {
      if (this.showCfg) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    },
    click: function () {
      if (this.cruci.w !== undefined && this.cruci.w > 0) {
        this.scroll();
      }
    },
  },
  destroyed() {
    speech.cancel();
    clearInterval(this.interval0);
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    clearInterval(this.interval4);
  },
  mounted() {
    let count = 0;
    this.setLoading(true);
    //Asigna la propiedad
    this.cruci = this.crucigrama;
    cruci = this.crucigrama;

    let s = this.getLocal('size');
    this.size = s ? parseInt(s) : 1;
    s = this.getLocal('audio');
    this.audio = s ? parseInt(s) : 2;
    s = this.getLocal('sizeK');
    this.sizeK = s ? parseInt(s) : 1;
    s = this.getLocal('teclasCercanas');
    this.teclasCercanas = s !== null ? s === 'true' : true;

    s = this.getLocal('mostrarCompletitud');
    this.mostrarCompletitud = s !== null ? s === 'true' : false;

    s = this.getLocal('lectorPregunta');
    this.lectorPregunta = s !== null ? s === 'true' : false;

    s = this.getLocal(`-${this.cruci.id}` + 'completado');
    this.percent = s ? parseInt(s) : 0;
    s = this.getLocal('modo');
    this.teclas = (s ? s : 'FACIL') === 'FACIL';
    s = this.getLocal('fondo');
    this.fondo = s ? parseInt(s) : 2;
    // document.body.style.background = `url(/img/bg${this.fondo}.png)`;

    //console.log('>>>>>> ' + `-${this.cruci.id}`);
    //llena las matrices con datos vacios
    let data = [];
    let data2 = [];
    let data3 = [];
    data3.push([]);
    for (let i = 0; i < this.cruci.alto; i++) {
      data.push([]);
      data2.push([]);
      data3.push([]);
      for (let j = 0; j < this.cruci.ancho; j++) {
        data[i].push(BLACK_CHAR);
        data2[i].push(BLACK_CHAR);
        data3[i].push([]);
      }
      data3[i].push([]);
    }
    for (let j = 0; j <= this.cruci.ancho; j++) {
      data3[this.cruci.alto].push([]);
    }
    //Llena las matrices con los datos del crucigrama

    this.cruci.preguntas.forEach((pregunta) => {
      let x = pregunta.x;
      let y = pregunta.y;
      //CHECK
      for (let i = 0; i < pregunta.palabra.length; i++)
        try {
          data[y][x] = SPACE_CHAR;
          data2[y][x] = pregunta.palabra[i];
          if (pregunta.horizontal) {
            x++;
          } else {
            y++;
          }
        } catch (e) {
          console.log(e);
        }
    });

    this.dataText = ''; //(BLACK_CHAR.repeat(this.cruci.ancho) + '\n').repeat(this.cruci.alto);
    this.dataSol = data2;

    let found = this.getLocal(`-${this.cruci.id}`);

    if (found) {
      found = JSON.parse(found);
      this.completado = found.completado;
      this.data = found.data;
      this.stars = found.stars ? found.stars : 0;
      this.click = found.click;

      let time = found.time ? found.time : 0;
      this.t0 = Date.now() - time * 1000;
      this.time = time;
      for (let p of this.cruci.preguntas) {
        let s = '';
        for (let i = 0; i < p.palabra.length; i++)
          try {
            s += this.data[p.y + (p.horizontal ? 0 : i)][p.x + (p.horizontal ? i : 0)];
          } catch (e) {
            console.log(e);
          }
        let oka = s === p.palabra;
        p.ok = oka;
      }
    } else {
      this.cruci.preguntas.forEach((p) => {
        p.ok = false;
      });
      this.storePlay();
      this.time = 0;
      this.t0 = Date.now();
      this.completado = false;
      this.data = data;
      this.pregunta = this.cruci.preguntas[0];
      this.click = [this.pregunta.x, this.pregunta.y];
      this.store();
    }

    this.data.forEach((row, y) => {
      row.forEach((col, x) => {
        if (col !== SPACE_CHAR) {
          let found = false;
          this.cruci.preguntas.forEach((p) => {
            if (p.horizontal) {
              if (p.y === y && p.x <= x && p.x + p.palabra.length > x) {
                found = true;
              }
            } else {
              if (p.x === x && p.y <= y && p.y + p.palabra.length > y) {
                found = true;
              }
            }
          });
          if (!found) {
            this.data[y][x] = BLACK_CHAR;
          }
        }
      });
    });
    //llenar this.data con black_char si es la coordenada es parte de una palabra, pero no ha sido completada
    this.cruci.preguntas.forEach((p) => {
      let x = p.x;
      let y = p.y;
      for (let i = 0; i < p.palabra.length; i++) {
        if (this.data[y][x] !== this.dataSol[y][x]) {
          this.data[y][x] = SPACE_CHAR;
        }
        if (p.horizontal) {
          x++;
        } else {
          y++;
        }
      }
    });

    if (!this.completado) {
      this.interval0 = setInterval(() => {
        if (!this.completado) {
          const time = Math.round((Date.now() - this.t0) / 1000);
          this.ftime(time);
        } else {
          this.time = Math.round((Date.now() - this.t0) / 1000);
          clearInterval(this.interval0);
        }
      }, 1000);
      //this.updateCanvasSize();
    }

    this.cruci.preguntas.forEach((p) => {
      for (let i = 0; i < p.palabra.length + 1; i++)
        try {
          let y = p.y + (p.horizontal ? 0 : i);
          let x = p.x + (p.horizontal ? i : 0);
          data3[y][x].push(p);
        } catch (e) {
          console.log(e);
        }
    });

    this.dataPreg = data3;

    const result = this.dataPreg[this.click[1]][this.click[0]];
    if (result.length > 0) {
      this.pregunta = result[0];
    }
    setTimeout(() => {
      this.scroll(); //SI VA
    }, 500);
    this.fadeIn();

    let grillaPre = this.$refs.grilla;
    const fn = (x) => {
      grillaPre = this.$refs.grilla;
      const grillaCanvas = this.$refs.grillaBg;
      if (grillaPre && grillaCanvas) {
        this.cruci.w = grillaPre.offsetWidth - 25 - this.grillaAddX[this.size];
        this.cruci.h = grillaPre.offsetHeight - 21;
        grillaCanvas.style.width = this.cruci.w + 'px';
        grillaCanvas.style.height = this.cruci.h + 'px';
        this.drawCanvas();
      }
    };
    document.body.onresize = fn;
    new ResizeObserver(fn).observe(grillaPre);

    this.drawCanvas();
  },
  methods: {
    tipWord() {
      if (!this.teclas || !this.pregunta) {
        return '';
      }
      let word = this.pregunta.palabra;

      for (let i = 0; i < word.length; i++) {
        let letra = this.data[this.pregunta.y + (this.pregunta.horizontal ? 0 : i)][this.pregunta.x + (this.pregunta.horizontal ? i : 0)];
        if (word.charAt(i) === letra) {
          word = word.substring(0, i) + '_' + word.substring(i + 1);
        }
      }

      return word.replace(/Á|Ä/, 'A').replace(/É|Ë/, 'E').replace(/Í|Ï/, 'I').replace(/Ú|Ü/, 'U').replace(/Ó|Ö/, 'O');
    },

    ftime(time) {
      const element = this.$refs.ftime;
      if (element) {
        // console.log('ftime ' + time);
        if (time >= 0) {
          const minutes = Math.floor(time / 60) % 60;
          const seconds = time % 60;
          const hours = Math.floor(time / 3600);
          const text = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
          element.innerHTML = text;
        }
        // console.log('ftime ' + element.innerHTML);
      }
    },
    updatellenado() {
      //retorna un string con espacios en blanco y espacios con letras correctas de la palabra actual

      if (!this.mostrarCompletitud || this.pregunta === undefined) {
        this.llenado = '';
        return;
      }
      let palabraLlenada = '';
      let x = this.pregunta.x;
      let y = this.pregunta.y;
      try {
        for (let i = 0; i < this.pregunta.palabra.length; i++) {
          if (this.data[y][x][0] === this.pregunta.palabra[i]) {
            palabraLlenada += this.pregunta.palabra[i];
          } else {
            //llena la palabra con _ o con # si corresponde al cursor actual
            if (x === this.click[0] && y === this.click[1]) {
              palabraLlenada += '■';
            } else {
              palabraLlenada += '─';
            }
          }
          if (this.pregunta.horizontal) {
            x++;
          } else {
            y++;
          }
        }
      } catch (error) {
        palabraLlenada = error.message;
      }
      this.llenado = palabraLlenada;
    },
    fadeIn(delay = 200, maxOpacity = 0.7) {
      if (this.$refs.grillaCont !== undefined) {
        this.$refs.grill.style.opacity = 0;
        this.$refs.grillaCont.style.opacity = 0;
        setTimeout(() => {
          let opacity = 0;
          this.gc();
          let first = true;
          this.interval1 = setInterval(() => {
            if (first) {
              this.drawCanvas();
              first = false;
            }
            opacity += 0.05;
            if (this.$refs.grillaCont !== undefined) this.$refs.grillaCont.style.opacity = opacity;
            if (this.$refs.grillaCont !== undefined) this.$refs.grill.style.opacity = opacity * 1.2;

            if (opacity >= maxOpacity) {
              this.gc();
              if (this.$refs.grill) {
                this.$refs.grill.style.opacity = 1;
              }
              this.setLoading(false);
              clearInterval(this.interval1);
            }
          }, 10);
        }, delay);
      }
    },
    currentWord() {
      let pregunta = this.pregunta;
      let s = '';
      if (pregunta !== undefined) {
        for (let i = 0; i < pregunta.palabra.length; i++) {
          let y = pregunta.y + (pregunta.horizontal ? 0 : i);
          let x = pregunta.x + (pregunta.horizontal ? i : 0);
          s += this.data[y][x];
        }
      }
      return s;
    },

    web() {
      this.playClick(this.audio);
      window.open('https://www.google.com/search?q=' + (this.pregunta.ok ? this.pregunta.palabra + ' = ' : '') + this.pregunta.pregunta, '_blank', 'noreferrer');
    },
    submit2(data) {
      if (data.router) {
        setTimeout(() => {
          this.$router.push(data.router).catch((err) => {});
        }, 10);
      }
      /////////
      else if (data.click === 'delete') {
        this.delete();
      } else if (data.click === 'publicar') {
        this.publicar();
      } else if (data.click === 'size0') {
        this.size = 0;
        this.setLocal('size', 0);
        setTimeout(() => {
          this.scroll(); //si va
        }, 50);
      } else if (data.click === 'size1') {
        this.size = 1;
        this.setLocal('size', 1);
        setTimeout(() => {
          this.scroll(); //si va
        }, 50);
      } else if (data.click === 'size2') {
        this.size = 2;
        this.setLocal('size', 2);
        setTimeout(() => {
          this.scroll(); //si va
        }, 50);
      }
      /////////
      else if (data.click === 'fondo1') {
        this.setLocal('fondo', 1);
        document.body.style.background = `url(/img/bg1.png)`;
        this.fondo = 1;
      } else if (data.click === 'fondo2') {
        document.body.style.background = `url(/img/bg2.png)`;
        this.setLocal('fondo', 2);
        this.fondo = 2;
      } else if (data.click === 'fondo3') {
        document.body.style.background = `url(/img/bg3.png)`;
        this.setLocal('fondo', 3);
        this.fondo = 3;
      }
      /////////
      else if (data.click === 'sizeK0') {
        this.sizeK = 0;
        this.setLocal('sizeK', 0);
      } else if (data.click === 'sizeK1') {
        this.sizeK = 1;
        this.setLocal('sizeK', 1);
      } else if (data.click === 'sizeK2') {
        this.sizeK = 2;
        this.setLocal('sizeK', 2);
      }
      /////////
      else if (data.click === 'set1') {
        this.audio = 1;
        this.setLocal('audio', 1);
      } else if (data.click === 'set2') {
        this.audio = 2;
        this.setLocal('audio', 2);
      } else if (data.click === 'mudo') {
        this.audio = 3;
        this.setLocal('audio', 3);
      }
      /////////
      else if (data.click === 'reinit') {
        this.removeLocal(`-${this.cruci.id}`);
        this.removeLocal(`-${this.cruci.id}` + 'completado');
        for (let pregunta of this.cruci.preguntas) {
          pregunta.ok = false;
        }
        for (let x = 0; x < this.cruci.ancho; x++) {
          for (let y = 0; y < this.cruci.alto; y++) {
            if (this.data[y][x] !== BLACK_CHAR) {
              this.data[y][x] = SPACE_CHAR;
            }
          }
        }
        this.completado = false;
        this.pregunta = this.cruci.preguntas[0];
        this.click = [this.pregunta.x, this.pregunta.y];
        this.t0 = Date.now();
        this.time = 0;

        this.interval2 = setInterval(() => {
          console.log('interval2 ' + Date.now());
          if (!this.completado) {
            const time = Math.round((Date.now() - this.t0) / 1000);
            this.ftime(time);
          } else {
            this.time = Math.round((Date.now() - this.t0) / 1000);
            clearInterval(this.interval2);
          }
        }, 1000);

        // let interv = setInterval(() => {
        //   if (!this.completado) {
        //     this.time = Math.round((Date.now() - this.t0) / 1000);
        //   } else {
        //     clearInterval(interv);
        //   }
        // }, 1000);
        this.playComplete(this.audio);
        //reinit crucigrama
        this.drawCanvas();
        this.store();
        this.$router.go(-1);
      } else if (data.click === 'share') {
        this.notification('Pronto podrás invitar a tus amigos a jugar contigo...');
      } else if (data.click === 'teclasCercanas') {
        this.teclasCercanas = !this.teclasCercanas;
        this.setLocal('teclasCercanas', this.teclasCercanas);
      } else if (data.click === 'mostrarCompletitud') {
        this.mostrarCompletitud = !this.mostrarCompletitud;
        this.setLocal('mostrarCompletitud', this.mostrarCompletitud);
        this.updatellenado();
      } else if (data.click === 'lectorPregunta') {
        this.lectorPregunta = !this.lectorPregunta;
        this.setLocal('lectorPregunta', this.lectorPregunta);
      }

      /////////
      else if (data.click === 'star1') {
        this.storeStars(1);
        this.notification('¡Gracias por tu voto!');
      } else if (data.click === 'star2') {
        this.storeStars(2);
        this.notification('¡Gracias por tu voto!');
      } else if (data.click === 'star3') {
        this.storeStars(3);
        this.notification('¡Gracias por tu voto!');
      } else if (data.click === 'star4') {
        this.storeStars(4);
        this.notification('¡Gracias por tu voto!');
      } else if (data.click === 'star5') {
        this.storeStars(5);
        this.notification('¡Gracias por tu voto!');
      }

      if (data.click === 'comment') {
        this.notification('Pronto se podrá comentar...');
      }

      if (data.click === '' || data.click == 'reinit') {
        this.fadeIn();
        this.showCfg = false;
      }

      if (data.router === undefined && data.click !== '') {
        this.playClick(this.audio);
      }
    },
    keyboard(evt) {
      let code = evt.key;

      if (!this.showCfg) {
        code = code.toUpperCase();
        if (code === 'BACKSPACE') {
          code = '⌫';
        }
        if (code === 'TAB') {
          //console.log(evt.shiftKey);
          code = evt.shiftKey ? '<' : '>';
        } else if (code === 'ARROWLEFT') {
          code = evt.ctrlKey ? '<' : '←';
        } else if (code === 'ARROWUP') {
          code = '↑';
        } else if (code === 'ARROWDOWN') {
          code = '↓';
        } else if (code === 'ARROWRIGHT') {
          code = evt.ctrlKey ? '>' : '→';
        } else if (code === 'DEAD') {
          code = '´';
        } else if (code === 'END') {
          code = '}';
        } else if (code === 'HOME') {
          code = '{';
        }
        if (code.length === 1) {
          this.keyPress(code);
        }
      } else if (code === 'Escape') {
        this.showCfg = false;
        this.playClick(this.audio);
        this.fadeIn();
        this.$router.go(-1);
      }
    },
    async storePlay() {
      if (this.uid) {
        const db = getFirestore();
        await updateDoc(doc(db, 'social', this.cruci.id), {
          plays: increment(1),
        }).catch((error) => {
          console.error('Error writing document: ', error);
        });
      }
    },
    async storeFinish() {
      this.setLocal(`-${this.cruci.id}`, JSON.stringify({ time: this.time, completado: this.completado === true, click: this.click, data: this.data, stars: this.stars }));

      const db = getFirestore();
      await updateDoc(doc(db, 'social', this.cruci.id), {
        times: increment(this.time),
        timesCount: increment(1),
      })
        .catch((error) => {
          console.error('Error writing document: ', error);
        })
        .then(() => {
          if (this.cruci.fromLocal) {
            this.cruci.times = (this.cruci.times || 0) + this.time;
            this.cruci.timesCount = (this.cruci.timesCount || 0) + 1;
            const data2 = { plays: this.cruci.plays, stars: this.cruci.stars, times: this.cruci.times, timesCount: this.cruci.timesCount, votos: this.cruci.votos };
            this.setLocal(this.cruci.id + 'cruciData', JSON.stringify(data2));
          }
        });
    },
    async storeStars(stars) {
      const db = getFirestore();

      const delta = stars - this.stars;
      const deltaVotos = this.stars === 0 ? 1 : 0;

      await updateDoc(doc(db, 'social', this.cruci.id), {
        stars: increment(delta),
        votos: increment(deltaVotos),
      })
        .catch((error) => {
          console.error('Error writing document: ', error);
        })
        .then(() => {
          if (this.cruci.fromLocal) {
            this.cruci.stars = (this.cruci.stars || 0) + delta;
            this.cruci.votos = (this.cruci.votos || 0) + deltaVotos;
            const data2 = { plays: this.cruci.plays, stars: this.cruci.stars, times: this.cruci.times, timesCount: this.cruci.timesCount, votos: this.cruci.votos };
            this.setLocal(this.cruci.id + 'cruciData', JSON.stringify(data2));
          }
        });

      this.stars = stars;
      this.store();
    },
    key(evt) {
      let text = evt.target.value.toUpperCase().replace(/[^A-ZÑÁÉÍÓÚÄËÏÖÜ·]/g, '');
      text = text + '·'.repeat(Math.max(0, this.largo - text.length));
      text = text.substring(0, this.largo);
      const pos = evt.target.selectionStart;
      if (evt.key.length === 1) {
        text = text.substring(0, pos) + text.substring(pos + 1);
      }

      evt.target.value = text;
      evt.target.selectionStart = pos;
      evt.target.selectionEnd = pos;
      //console.log(text, pos, evt.key);
    },
    scroll() {
      if (this.click[0] >= 0 && this.click[1] >= 0) {
        const left = Math.max(0, -window.innerWidth / 2 + Math.trunc((this.click[0] * this.cruci.w) / this.cruci.ancho)) * this.zoom * this.zoom;
        //console.log(this.click, this.$refs.grillaBg.getBoundingClientRect());
        const centerOffset = -Math.trunc((window.innerHeight - this.$refs.keyComponent.$el.children[0].clientHeight) / this.zoom / 2);
        const top = Math.max(0, this.$refs.grillaCont.offsetTop + centerOffset + (this.isMobile ? 120 : 70) + Math.trunc((this.click[1] * this.cruci.h) / this.cruci.alto)) * this.zoom * this.zoom;
        const distancia = Math.abs(window.scrollX - left) + Math.abs(window.scrollY - top);
        // console.log(distancia, window.scrollX, left, window.scrollY, top);
        window.scrollTo({
          left,
          top,
          behavior: 'instant', //distancia < 50 ? 'instant' : 'smooth',
        });
        //scroll
        this.drawCanvas();
      }
    },
    keyCfg() {
      this.keyPress('cfg');
    },
    keyPress(key, pos) {
      //console.log('grilla', key);
      if (key === '') {
        if (this.teclasCercanas && this.isMobile) {
          key = this.keys[0][Math.trunc((this.keys[0].length * (pos[0] + 0.5)) / 1.1)];
        } else {
          return;
        }
      }
      if (key === undefined) {
        return;
      }

      if (key === '→' || key === '←' || key === '↑' || key === '↓' || key === 'cfg' || key === 'tip' || key === '{' || key === '}' || key === '<' || key === '>' || !this.completado) {
        if (key !== 'tip' && key !== 'cfg' && key !== '1' && key !== '2' && key !== '3' && key !== '4') {
          this.playClick(this.audio);
        }
        if (key === 'tip') {
          this.web();
          return;
        }
        if (key === 'cfg') {
          this.showCfg = !this.showCfg;
          return;
        }
        if (key === '{' || key === '}') {
          if (key === '{') {
            this.click = [this.pregunta.x, this.pregunta.y];
          } else {
            this.click = [this.pregunta.x + (this.pregunta.horizontal ? this.pregunta.palabra.length : 0), this.pregunta.y + (!this.pregunta.horizontal ? this.pregunta.palabra.length : 0)];
          }
        } else if (key === '<' || key === '>') {
          const add = key === '<' ? -1 : 1;
          if (this.pregunta === undefined) {
            const result = this.dataPreg[this.click[1]][this.click[0]];
            if (result.length > 0) {
              this.pregunta = result[0];
            }
          }
          this.dataPreg[this.click[1]][this.click[0]].forEach((pregunta) => {
            if (pregunta.palabra === this.pregunta.palabra) {
              this.pregunta = pregunta;
            }
          });
          let idx = this.cruci.preguntas.indexOf(this.pregunta);

          if (this.completado) {
            if (key === '<') {
              idx = idx - 1;
              idx === -1 ? (idx = this.cruci.preguntas.length - 1) : idx;
            } else if (key === '>') {
              idx = (idx + 1) % this.cruci.preguntas.length;
            }
            this.pregunta = this.cruci.preguntas[idx];
            this.click = [this.pregunta.x, this.pregunta.y];
          } else {
            let found = false;

            {
              let position = Math.abs(this.click[0] - this.pregunta.x) + Math.abs(this.click[1] - this.pregunta.y);
              const wordSize = this.pregunta.palabra.length;
              let letterState = 0;
              let posSaved = -10;
              while (position < wordSize + 1 && position >= 0 && !found) {
                let x = this.pregunta.x + (this.pregunta.horizontal ? position : 0);
                let y = this.pregunta.y + (this.pregunta.horizontal ? 0 : position);
                if (x >= 0 && x < this.cruci.ancho && y >= 0 && y < this.cruci.alto) {
                  if (letterState === 0) {
                    if (this.data[y][x] === this.dataSol[y][x]) {
                      letterState = 1;
                    } else if (add < 0) {
                      letterState = 2;
                      posSaved = position;
                    }
                  }
                  if (letterState === 1) {
                    if (this.data[y][x] !== this.dataSol[y][x]) {
                      if (add > 0) {
                        this.click = [x, y];
                        found = true;
                      } else {
                        letterState = 2;
                      }
                    }
                  }
                  if (letterState === 2) {
                    let x0 = this.pregunta.x + (this.pregunta.horizontal ? position - 1 : 0);
                    let y0 = this.pregunta.y + (this.pregunta.horizontal ? 0 : position - 1);
                    if (x0 < 0 || y0 < 0 || this.data[y0][x0] === this.dataSol[y0][x0]) {
                      if (position === posSaved) {
                        letterState = 1;
                      } else {
                        this.click = [x, y];
                        found = true;
                      }
                    }
                  }
                }
                position += add;
              }
            }
            ///
            if (!found) {
              let count = 0;
              while (!found) {
                idx += add;
                if (idx < 0) {
                  count++;
                  idx = this.cruci.preguntas.length - 1;
                  if (count > 1) {
                    return;
                  }
                }
                if (idx >= this.cruci.preguntas.length) {
                  idx = 0;
                  count++;
                  if (count > 1) {
                    return;
                  }
                }
                if (!this.cruci.preguntas[idx].ok) found = true;
              }
              this.pregunta = this.cruci.preguntas[idx];

              let x = this.pregunta.x,
                y = this.pregunta.y;
              while (this.data[y][x] === this.dataSol[y][x]) {
                x += this.pregunta.horizontal ? 1 : 0;
                y += this.pregunta.horizontal ? 0 : 1;
              }
              this.click = [x, y];
            }
          }
        } else if (((this.isMobile ? '←→' : '') + ' QWERTYUIOPASDFGHJKLÑZXCVBNM1234').indexOf(key) !== -1 && this.click[0] < this.cruci.ancho && this.click[1] < this.cruci.alto && this.data[this.click[1]][this.click[0]] !== BLACK_CHAR) {
          if (key === 'A' && this.dataSol[this.click[1]][this.click[0]] === 'Á') {
            key = 'Á';
          } else if (key === 'E' && this.dataSol[this.click[1]][this.click[0]] === 'É') {
            key = 'É';
          } else if (key === 'I' && this.dataSol[this.click[1]][this.click[0]] === 'Í') {
            key = 'Í';
          } else if (key === 'O' && this.dataSol[this.click[1]][this.click[0]] === 'Ó') {
            key = 'Ó';
          } else if (key === 'U' && this.dataSol[this.click[1]][this.click[0]] === 'Ú') {
            key = 'Ú';
          }
          if (key === 'A' && this.dataSol[this.click[1]][this.click[0]] === 'Á') {
            key = 'Ä';
          } else if (key === 'E' && this.dataSol[this.click[1]][this.click[0]] === 'Ë') {
            key = 'Ë';
          } else if (key === 'I' && this.dataSol[this.click[1]][this.click[0]] === 'Ï') {
            key = 'Ï';
          } else if (key === 'O' && this.dataSol[this.click[1]][this.click[0]] === 'Ö') {
            key = 'Ö';
          } else if (key === 'U' && this.dataSol[this.click[1]][this.click[0]] === 'Ü') {
            key = 'Ü';
          }

          let correctKey = this.dataSol[this.click[1]][this.click[0]];
          //Elimina las tildes
          let kkey = key.replace('Á', 'A').replace('É', 'E').replace('Í', 'I').replace('Ó', 'O').replace('Ú', 'U').replace('Ä', 'A').replace('Ë', 'E').replace('Ï', 'I').replace('Ö', 'O').replace('Ü', 'U');

          let ckey = correctKey.replace('Á', 'A').replace('É', 'E').replace('Í', 'I').replace('Ó', 'O').replace('Ú', 'U').replace('Ä', 'A').replace('Ë', 'E').replace('Ï', 'I').replace('Ö', 'O').replace('Ü', 'U');

          let matchKey = this.dataSol[this.click[1]][this.click[0]] === key;
          if (this.teclasCercanas && this.isMobile) {
            let keyX, keyY, correctX, correctY;
            for (let y = 0; y < this.keys.length; y++) {
              for (let x = 0; x < this.keys[y].length; x++) {
                if (this.keys[y][x] === kkey) {
                  keyX = x;
                  keyY = y;
                }
                if (this.keys[y][x] === ckey) {
                  correctX = x;
                  correctY = y;
                }
              }
            }
            let dx = keyX - correctX + pos[0];
            let dy = keyY - correctY + pos[1];
            let distance = Math.sqrt(dx * dx + dy * dy);
            //Verificar que intento anterior fue correcto
            if (distance <= 1.1) {
              if ('1234'.includes(key)) {
                this.playClick(this.audio);
              }
              key = correctKey;

              matchKey = true;
            } else if (!' '.includes(key)) {
              if (!'←→'.includes(key)) {
                this.playFail(this.audio);
                this.store();
                this.scroll();
                return;
              }
            }
          } else if (ckey !== kkey && !' ←→'.includes(key)) {
            if (!this.tipWord().includes(key)) {
              this.playFail(this.audio);
              this.store();
              this.scroll();
            }
            return;
          }

          key = key === ' ' ? SPACE_CHAR : key;

          if (key !== '←' && key !== '→') {
            this.data[this.click[1]][this.click[0]] = key;

            for (let p of this.dataPreg[this.click[1]][this.click[0]]) {
              let s = '';
              for (let i = 0; i < p.palabra.length; i++) {
                s += this.data[p.y + (p.horizontal ? 0 : i)][p.x + (p.horizontal ? i : 0)];
              }

              let oka = s === p.palabra;
              if (!p.ok && oka) {
                this.playWord(this.audio);
                matchKey = true;
              }
              p.ok = oka;
            }

            let step = false;
            if (this.pregunta.horizontal && this.click[0] < this.cruci.ancho) {
              this.click[0]++;
              step = true;
            } else if (!this.pregunta.horizontal && this.click[1] < this.cruci.alto) {
              this.click[1]++;
              step = true;
            }
            //Avanza al siguiente espacio vacio dentro de la misma palabra
            if (step && matchKey) {
              let pos = Math.abs(this.click[0] - this.pregunta.x) + Math.abs(this.click[1] - this.pregunta.y);
              let x = this.click[0];
              let y = this.click[1];
              while (pos < this.pregunta.palabra.length && this.data[y][x] === this.dataSol[y][x]) {
                pos++;
                x = this.pregunta.x + (this.pregunta.horizontal ? pos : 0);
                y = this.pregunta.y + (this.pregunta.horizontal ? 0 : pos);
              }
              if (pos < this.pregunta.palabra.length) {
                this.click[0] = x;
                this.click[1] = y;
              }
            }
            let respuesta = '';
            for (let i = 0; i < this.pregunta.palabra.length; i++) {
              const x = this.pregunta.x + (this.pregunta.horizontal ? i : 0);
              const y = this.pregunta.y + (this.pregunta.horizontal ? 0 : i);
              respuesta = respuesta + this.data[y][x];
            }
          }
        }

        if (key === '→' || key === '←' || key === '↑' || key === '↓') {
          /////////////////////////////////////////
          if (this.isMobile) {
            if (key === '←' && ((!this.pregunta.horizontal && this.click[0] >= 0) || (this.click[0] > 0 && this.pregunta.horizontal))) {
              if (this.pregunta.horizontal) {
                try {
                  if (this.data[this.click[1]][this.click[0] - 1] !== BLACK_CHAR) {
                    this.click[0]--;
                    if (!this.pregunta.horizontal) {
                      this.setPregunta(this.click[0], this.click[1]);
                    }
                  }
                } catch (error) {}
              } else {
                try {
                  if (this.data[this.click[1] - 1][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1] - 1][this.click[0]] && this.dataPreg[this.click[1] - 1][this.click[0]].length > 0) {
                    this.click[1]--;
                    if (this.pregunta.horizontal) {
                      this.setPregunta(this.click[0], this.click[1]);
                    }
                  }
                } catch (error) {}
              }
            } else if (key === '→') {
              if (this.pregunta.horizontal) {
                try {
                  if (this.data[this.click[1]][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1]][this.click[0] + 1] && this.dataPreg[this.click[1]][this.click[0] + 1].length > 0) {
                    this.click[0]++;
                    if (!this.pregunta.horizontal) {
                      this.setPregunta(this.click[0], this.click[1]);
                    }
                  }
                } catch (error) {}
              } else {
                try {
                  if (this.data[this.click[1]][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1] + 1][this.click[0]] && this.dataPreg[this.click[1] + 1][this.click[0]].length > 0) {
                    this.click[1]++;
                    if (this.pregunta.horizontal) {
                      this.setPregunta(this.click[0], this.click[1]);
                    }
                  }
                } catch (error) {}
              }
            }
          } else {
            //Desktop no keyboard on screen
            if (key === '←' && this.click[0] > 0) {
              try {
                if (this.data[this.click[1]][this.click[0] - 1] !== BLACK_CHAR) {
                  this.click[0]--;
                  if (!this.pregunta.horizontal) {
                    this.setPregunta(this.click[0], this.click[1]);
                  }
                }
              } catch (error) {}
            } else if (key === '→' && this.click[0] < this.cruci.ancho) {
              try {
                if (this.data[this.click[1]][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1]][this.click[0] + 1] && this.dataPreg[this.click[1]][this.click[0] + 1].length > 0) {
                  this.click[0]++;
                  if (!this.pregunta.horizontal) {
                    this.setPregunta(this.click[0], this.click[1]);
                  }
                }
              } catch (error) {}
            } else if (key === '↓' && this.click[1] < this.cruci.alto) {
              try {
                if (this.data[this.click[1]][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1] + 1][this.click[0]] && this.dataPreg[this.click[1] + 1][this.click[0]].length > 0) {
                  this.click[1]++;
                  if (this.pregunta.horizontal) {
                    this.setPregunta(this.click[0], this.click[1]);
                  }
                }
              } catch (error) {}
            } else if (key === '↑' && this.click[1] > 0) {
              try {
                if (this.data[this.click[1] - 1][this.click[0]] !== BLACK_CHAR && this.dataPreg[this.click[1] - 1][this.click[0]] && this.dataPreg[this.click[1] - 1][this.click[0]].length > 0) {
                  this.click[1]--;
                  if (this.pregunta.horizontal) {
                    this.setPregunta(this.click[0], this.click[1]);
                  }
                }
              } catch (error) {}
            }
          }

          /////////////////////////////////////////
        } else if (key === '⌫') {
          if (this.pregunta.horizontal && this.click[0] > 0 && this.data[this.click[1]][this.click[0] - 1] !== BLACK_CHAR) {
            this.click[0]--;
            this.data[this.click[1]][this.click[0]] = SPACE_CHAR;
          } else if (!this.pregunta.horizontal && this.click[1] > 0 && this.data[this.click[1] - 1][this.click[0]] !== BLACK_CHAR) {
            this.click[1]--;
            this.data[this.click[1]][this.click[0]] = SPACE_CHAR;
          }
          for (let p of this.dataPreg[this.click[1]][this.click[0]]) {
            let s = '';
            for (let i = 0; i < p.palabra.length; i++) {
              s += this.data[p.y + (p.horizontal ? 0 : i)][p.x + (p.horizontal ? i : 0)];
            }
            let oka = s === p.palabra;
            if (p.ok && !oka) {
              this.playFail(this.audio);
            }
            p.ok = oka;
          }
        }
        this.store();
        this.scroll();
      }
    },
    store() {
      const STORE_DELAY = 2000;
      const now = Date.now();
      if (!this.completado) {
        this.time = Math.round((now - this.t0) / 1000);
      }
      lastStore = now;
      this.percent = Math.round((100 * this.cruci.preguntas.reduce((a, p) => a + (p.ok ? 1 : 0), 0)) / this.cruci.preguntas.length);
      setTimeout(() => {
        if (now === lastStore) {
          this.setLocal(`-${this.cruci.id}`, JSON.stringify({ time: this.time - Math.trunc(STORE_DELAY / 1000), completado: this.completado === true, click: this.click, data: this.data, stars: this.stars }));
          this.setLocal(`-${this.cruci.id}` + 'completado', this.percent);
          //console.log('STORE -----------------------------------------------');
        }
      }, STORE_DELAY);
    },
    drawCanvas() {
      if (this.pregunta === undefined) {
        this.pregunta = this.cruci.preguntas[0];
        this.click = [this.pregunta.x, this.pregunta.y];
      }
      this.updatellenado();
      this.dataText = this.data.map((row) => row.join('')).join('\n');
      const canvas = this.$refs.grillaBg;
      const grilla = this.$refs.grilla;
      if (grilla !== undefined && canvas !== undefined) {
        if (canvas !== undefined) {
          canvas.width = this.cruci.ancho;
          canvas.height = this.cruci.alto;
          canvas.style.left = grilla.offsetLeft + 'px';
          var ctx = canvas.getContext('2d');
          let ok = true;
          for (let i = 0; i < this.cruci.alto; i++) {
            for (let j = 0; j < this.cruci.ancho; j++) {
              ctx.fillStyle = this.data[i][j] === BLACK_CHAR ? this.grillaColor : this.data[i][j] === this.dataSol[i][j] ? '#88ff88' : this.grillaColorBlanco;
              if (ok && this.data[i][j] !== this.dataSol[i][j]) {
                ok = false;
              }
              ctx.fillRect(j, i, 1, 1);
            }
          }
          if (!ok && this.complete) {
            //console.log('FALSE');
            this.complete = false;
          }
          if (this.pregunta !== undefined) {
            let x = this.pregunta.x;
            let y = this.pregunta.y;

            for (let i = 0; i < this.pregunta.palabra.length; i++) {
              ctx.fillStyle = this.dataSol[y][x] === this.data[y][x] ? '#88ff88' : '#ffff88';
              if (ok && ctx.fillStyle !== '#88ff88') {
                ok = false;
              }
              ctx.fillRect(x, y, 1, 1);
              if (this.pregunta.horizontal) {
                x++;
              } else {
                y++;
              }
            }
            if (ok) {
              if (!this.completado) {
                this.completado = true;
                this.store();
                setTimeout(() => {
                  this.playComplete(this.audio);
                  this.storeFinish();
                }, 500);
                let interval;
                setTimeout(() => {
                  this.interval3 = setInterval(() => {
                    console.log('interval3 ' + Date.now());
                    if (this.confetti < 1) {
                      this.confetti += 0.2;
                    } else {
                      clearInterval(this.interval3);
                    }
                  }, 100);
                  setTimeout(() => {
                    let interval;
                    this.interval4 = setInterval(() => {
                      console.log('interval4 ' + Date.now());
                      if (this.confetti > 0) {
                        this.confetti -= 0.4;
                      } else {
                        clearInterval(this.interval4);
                      }
                    }, 100);
                  }, 2200);
                }, 300);
                setTimeout(() => {
                  this.notification('¡FELICITACIONES!<br>CRUCIGRAMA COMPLETADO<br><br>Presione el botón de configuración<br> para calificar, comentar, reiniciar o compartir.</a>');
                }, 1000);
              }
            }
          }
        } else
          setTimeout(function () {
            this.drawCanvas();
          }, 500);

        const left = grilla.offsetLeft + Math.round(this.click[0] * this.cruci.w) / this.cruci.ancho;
        const top = Math.round(this.click[1] * this.cruci.h) / this.cruci.alto;
        this.cursorStyle = (this.pregunta.horizontal ? 'height: ' + Math.round(this.cruci.h / this.cruci.alto) + 'px; width: 5px;' : 'height: 6px; width: ' + Math.round(this.cruci.w / this.cruci.ancho) + 'px;') + ' left: ' + left + 'px; top: ' + top + 'px;';
        //console.log('DRAWCANVAS');
        //console.log(this.cruci.ancho, this.cruci.alto);
      }
    },
    gc() {
      //FIX THIS....que reaccione al cambio de resolucion
      const grilla = this.$refs.grilla;
      if (grilla) {
        let top = grilla.offsetTop + 'px';
        let left = grilla.offsetLeft - grilla.parentNode.scrollLeft + 'px';

        {
          this.top = top;
          this.left = left;
          // console.log('GC ' + left + ' ' + top);
          const cursorDiv = this.$refs.cursorDiv;
          if (cursorDiv) {
            cursorDiv.style.marginTop = this.top;
          }
        }
      }
    },
    wheel(event) {
      let data = 0;
      if (event.deltaY < 0) {
        data += 20;
      } else {
        data -= 20;
      }
      window.scrollTo({
        top: window.scrollY - data,
        behavior: 'instant',
      });
    },
    grillaMove(evt) {
      if (!this.isMobile && this.move !== undefined) {
        this.$refs.grilla.style.cursor = 'grabbing';
        this.move[0] = window.scrollX - evt.movementX;
        this.move[1] = window.scrollY - evt.movementY;
        window.scrollTo({
          top: this.move[1],
          left: this.move[0],
          behavior: 'instant',
        });
      }
    },
    grillaDown(evt) {
      if (!this.isMobile) {
        this.move = [0, 0];
      }
    },
    mouseleave() {
      if (!this.isMobile) {
        this.$refs.grilla.style.cursor = 'pointer';
        if (!this.isMobile && this.move !== undefined && Math.abs(this.move[0]) + Math.abs(this.move[1]) > 8) {
          this.move = undefined;
          return;
        }
      }
    },
    grillaClick(evt) {
      this.$refs.grilla.style.cursor = 'pointer';
      if (!this.isMobile && this.move !== undefined && Math.abs(this.move[0]) + Math.abs(this.move[1]) > 8) {
        this.move = undefined;
        return;
      }
      let canvas = this.$refs.grillaBg;
      const x = Math.trunc((evt.offsetX / this.zoom / canvas.clientWidth) * this.cruci.ancho);
      const y = Math.trunc((evt.offsetY / this.zoom / canvas.clientHeight) * this.cruci.alto);
      if (this.dataPreg[y][x] && this.dataPreg[y][x].length > 0) {
        this.playClick(this.audio);
        this.click = [x, y];
        this.setPregunta(x, y);
        this.store();
      }
      this.move = undefined;
    },
    setPregunta(x, y) {
      const pregs = this.dataPreg[y][x];
      //      cruci.preguntas.filter((pregunta) => (x >= pregunta.x && x < pregunta.x + pregunta.palabra.length && pregunta.horizontal && y === pregunta.y) || (y >= pregunta.y && y < pregunta.y + pregunta.palabra.length && !pregunta.horizontal && x === pregunta.x));

      let preguntaSeleccionada = undefined;

      if (pregs.length === 2) {
        if (this.pregunta?.palabra !== pregs[0].palabra && this.pregunta?.palabra !== pregs[1].palabra) {
          if (pregs[0].x === x && pregs[0].y === y && pregs[1].x === x && pregs[1].y === y) {
            if (pregs[0].horizontal) {
              preguntaSeleccionada = pregs[0];
            } else {
              preguntaSeleccionada = pregs[1];
            }
          } else if (pregs[0].x === x && pregs[0].y === y && (pregs[1].x !== x || pregs[1].y !== y)) {
            preguntaSeleccionada = pregs[0];
          } else if (pregs[1].x === x && pregs[1].y === y && (pregs[0].x !== x || pregs[0].y !== y)) {
            preguntaSeleccionada = pregs[1];
          } else {
            if (pregs[0].horizontal) {
              preguntaSeleccionada = pregs[0];
            } else {
              preguntaSeleccionada = pregs[1];
            }
          }
        } else {
          if (this.pregunta?.palabra === pregs[0].palabra) {
            preguntaSeleccionada = pregs[1];
          } else if (this.pregunta?.palabra === pregs[1].palabra) {
            preguntaSeleccionada = pregs[0];
          } else {
            preguntaSeleccionada = pregs[0];
          }
        }
      } else if (pregs.length === 1) {
        preguntaSeleccionada = pregs[0];
      } else {
        preguntaSeleccionada = undefined;
      }
      //this.drawCanvas({ contentRect: { width: this.w, height: this.h } }, this.data);
      if (preguntaSeleccionada !== undefined) {
        this.pregunta = preguntaSeleccionada;
      }
    },
    async publicar() {
      try {
        const now = this.cruci.public ? this.cruci.fecha : Timestamp.now();
        await updateDoc(doc(getFirestore(), 'social', this.cruci.id), {
          public: this.cruci.public ? false : true,
          fecha: now,
        });
        let dat = this.getData('data');
        if (dat) {
          const idx = dat.findIndex((d) => d.id === this.cruci.id);
          dat[idx].public = this.cruci.public ? false : true;
          dat[idx].fecha = now;
          this.setData('data', dat);
        }
        this.cruciData(this.cruci.id, { ...this.cruci, public: this.cruci.public ? false : true, fecha: now });
        this.$emit('repaint');
        this.notification('El crucigrama esta ' + (this.cruci.public ? 'oculto' : 'publico'));
      } catch (error) {
        this.notification(error);
      }
    },
    async delete() {
      if (confirm('¿Estas seguro de eliminar el crucigrama?')) {
        try {
          await deleteDoc(doc(getFirestore(), 'crosswords', this.cruci.id));
          await deleteDoc(doc(getFirestore(), 'social', this.cruci.id));
          const data = this.getData('data');
          if (data) {
            this.setData(
              'data',
              data.filter((d) => d.id !== this.cruci.id)
            );
          }
          this.removeLocal(`-${this.cruci.id}`);
          this.removeLocal(`-${this.cruci.id}` + 'completado');
          this.$router.back();
          this.notification('Crucigrama eliminado');
        } catch (error) {
          this.notification(error);
        }
      }
    },
  },
};
</script>
<style scoped>
.grill {
  outline: none;
  margin: 0;
  text-align: center;
}
.grill * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.grilla-cursor {
  pointer-events: none;
  overflow: visible;
  animation: colorchange 0.4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.8) 2px 2px 4px;
}

@keyframes colorchange {
  0% {
    background-color: yellow;
  }
  10% {
    background-color: yellow;
  }
  50% {
    background-color: transparent;
  }
  90% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}

.grilla-container {
  opacity: 0;
  overflow-y: clip;
  text-align: center;
  margin: 24px 0 !important;
}
.grilla-canvas {
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.5) 4px 10px 5px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;

  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
}

.grilla {
  cursor: pointer;
  display: inline-block;
  font-family: 'Ubuntu Mono', monospace;
  color: rgba(0, 0, 0, 0.69);
  letter-spacing: 12px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 24px;
  opacity: 0.99;
  padding-bottom: 20px;
  text-shadow: 1px 2px 2px rgb(25 12 18 / 22%);
}

.play {
  opacity: 1;
}
.noPlay {
  opacity: 0.3;
}
.grilla-top strong {
  font-size: 1.2em;
  font-weight: bold;
}

.confetti {
  background: url(/img/win.gif);
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;

  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.ti-settings {
  padding-bottom: 20px !important;
}
</style>
